import React from 'react';
import linkedin from '../assets/images/icons/linkedin.svg';
import twitter from '../assets/images/icons/twitter.svg';
import facebook from '../assets/images/icons/facebook.svg';
import instagram from '../assets/images/icons/instagram.svg';
import youtube from '../assets/images/icons/youtube.svg';
import medium from '../assets/images/icons/medium.svg';

interface SocialProps {
  className?: string;
  extended?: boolean;
}

const Social = ({ className, extended }: SocialProps) => {
  return (
    <div className={`social ${className}`}>
      <a
        href="https://www.linkedin.com/company/flowyourmoney"
        target="_blank"
        rel="noopener noreferrer"
        className="social__link"
      >
        <img src={linkedin} alt="ln" className="social__icon" />
      </a>
      <a
        href="https://twitter.com/flowyourmoney"
        target="_blank"
        rel="noopener noreferrer"
        className="social__link"
      >
        <img src={twitter} alt="tw" className="social__icon" />
      </a>
      <a
        href="https://www.facebook.com/flowyourmoney"
        target="_blank"
        rel="noopener noreferrer"
        className="social__link"
      >
        <img src={facebook} alt="fb" className="social__icon" />
      </a>
      <a
        href="http://instagram.com/flowyourmoney"
        target="_blank"
        rel="noopener noreferrer"
        className="social__link"
      >
        <img src={instagram} alt="in" className="social__icon" />
      </a>
      {extended && (
        <>
          <a
            href="https://www.youtube.com/channel/UCqc4YSOnW-J__YR_5o_yYSQ"
            target="_blank"
            rel="noopener noreferrer"
            className="social__link"
          >
            <img src={youtube} alt="in" className="social__icon" />
          </a>
          <a
            href="https://medium.com/@flowyourmoney"
            target="_blank"
            rel="noopener noreferrer"
            className="social__link"
          >
            <img src={medium} alt="in" className="social__icon" />
          </a>
        </>
      )}
    </div>
  );
};

export default Social;
