import create from 'zustand';

interface ModalState {
  modalState: 'closed' | 'open';
  url?: string;
  openModal(url?: string): void;
  closeModal(): void;
}

const useModal = create<ModalState>((set) => ({
  modalState: 'closed',
  url: undefined,
  openModal: (url) => set({ modalState: 'open', url }),
  closeModal: () => set({ modalState: 'closed' }),
}));

export default useModal;
