import React, { 
  // useState 
} from 'react';
import Modal from 'react-modal';
import useModal from '../hooks/useModal';
import shallow from 'zustand/shallow';
import { 
  useTranslation, 
  // Trans 
} from 'gatsby-plugin-react-i18next';
import close from '../assets/images/dialog/close.svg';
// import success from '../assets/images/dialog/success.svg';
// import error from '../assets/images/dialog/error.svg';
// import loader from '../assets/images/loader.svg';
// import PhoneInput from 'react-phone-input-2';
import '../styles/vendors/react-phone-input.scss';
const QRCode = require('qrcode.react');

Modal.setAppElement(`#___gatsby`);

export function getFlowButtonHandler(arg?: string) {
  const url = arg ? arg : quickLinkURL + document.location.search;
  const { openModal } = useModal.getState();
  window.innerWidth > 1024 ? openModal(url) : window.location.assign(url);
}

// const functionURL = 'https://inchworm-fox-3744.twil.io/send-sms';
const quickLinkURL = 'https://app.flowyour.money/download';

const Dialog = () => {
  const { modalState, closeModal, link } = useModal(
    ({ modalState, closeModal, url }) => ({
      modalState,
      closeModal,
      link: url || quickLinkURL
    }),
    shallow
  );
  // const [smsCodeState, setSmsCodeState] = useState<
  //   'idle' | 'loading' | 'success' | 'error'
  // >('idle');
  // const [phoneNumber, setPhoneNumber] = useState('');
  // const [isInvalid, setIsInvalid] = useState(false);

  const { t } = useTranslation('modal');

  // const setPhoneNumberHandler = (phone: string) => {
  //   if (phone.trim().length > 0) {
  //     setPhoneNumber(phone);
  //   }
  // };

  // const sendSmsMessage = () => {
  //   setSmsCodeState('loading');
  //   fetch(functionURL, {
  //     method: 'POST',
  //     headers: {
  //       'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  //     },
  //     body: new URLSearchParams({
  //       to: `+${phoneNumber}`,
  //       body: t('messageText', { link }),
  //     }).toString(),
  //   })
  //     .then((res) => {
  //       if (!res.ok) {
  //         throw new Error();
  //       }
  //       setSmsCodeState('success');
  //     })
  //     .catch(() => setSmsCodeState('error'))
  //     .finally(() => setPhoneNumber(''));
  // };

  // const inputValidator = () => {
  //   if (phoneNumber.trim().length < 11) {
  //     setIsInvalid(true);
  //     setTimeout(() => {
  //       setIsInvalid(false);
  //     }, 5000);
  //   } else {
  //     sendSmsMessage();
  //   }
  // };

  const onModalCloseHandler = () => {
    document.body.style.overflow = 'unset';
    closeModal();
    // setTimeout(() => {
    //   setSmsCodeState('idle');
    //   setPhoneNumber('');
    //   setIsInvalid(false);
    // }, 1000);
  };

  return (
    <Modal
      isOpen={modalState === 'open'}
      className="modal"
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onRequestClose={onModalCloseHandler}
      contentLabel="Get Started with Flow"
      closeTimeoutMS={400}
    >
      <button className="modal__close" onClick={onModalCloseHandler}>
        <img src={close} alt="x" />
      </button>
      {/* {smsCodeState !== 'success' && smsCodeState !== 'error' ? ( */}
      {true ? (
        <>
          <h2 className="u-color-primary modal__title">
            {t('defaultState.title')}
          </h2>
          <div className="u-color-primary modal__text">
            {t('defaultState.text')}
          </div>
          {/* <div className="modal__form">
            <div className="modal__wrapper">
              <PhoneInput
                country={'nl'}
                regions={'europe'}
                value={phoneNumber}
                countryCodeEditable={false}
                onChange={(phone) => setPhoneNumberHandler(phone)}
              />
              <button className="modal__btn" onClick={inputValidator}>
                {smsCodeState !== 'loading' ? (
                  t('defaultState.buttonTitle')
                ) : (
                  <img src={loader} alt="loading..." />
                )}
              </button>
            </div>
            <div className="modal__error">
              {isInvalid && t('validationText')}
            </div>
          </div> */}
          <div className="modal__store-badges">
            <a className="modal__store-badges__badge modal__store-badges__badge-google" href='https://play.google.com/store/apps/details?id=money.flowyour.flow&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
            </a>
            <a className="modal__store-badges__badge" href="https://apps.apple.com/nl/app/flow-de-slimme-geldverdeler/id1441919325?itsct=apps_box_badge&amp;itscg=30200">
              <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1599609600?h=7599e58eff13f0a607c43e9d7f80e245" alt="Download on the App Store" />
            </a>
          </div>
          <div className="modal__alt">{t('defaultState.or')}</div>
          <QRCode size={256} value={link} />
        </>
      ) : (
        <>
          {/* <img
            src={smsCodeState === 'success' ? success : error}
            alt="x"
            className="modal__logo"
          />
          <h2 className="modal__title">
            {t(
              `${
                smsCodeState === 'success' ? 'successState' : 'errorState'
              }.title`
            )}
          </h2>
          <div className="modal__text modal__text--result">
            {t(
              `${
                smsCodeState === 'success' ? 'successState' : 'errorState'
              }.text`
            )}
          </div>
          <div className="modal__resend">
            <Trans
              defaults="Didn't receive the link? <0>Resend it</0>"
              i18nKey="modal:resentText"
              components={[
                <button
                  className="modal__return u-color-secondary"
                  onClick={() => setSmsCodeState('idle')}
                />,
              ]}
            />
          </div> */}
        </>
      )}
    </Modal>
  );
};

export default Dialog;
